<template lang="pug">
div
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import pick from 'lodash/pick'
import { hydrateWhenIdle } from 'vue-lazy-hydration'
import breadcrumbsMixin from '~~/mixins/breadcrumbsMixin'
import metatagsMixin from '~~/mixins/metatags/metatagsMixin'

export default {
  name: 'BasePageComponent',

  components: {
    BackToTopComponent: hydrateWhenIdle(() =>
      import('~~/components/ListingPage/BackToTop')
    )
  },

  mixins: [metatagsMixin, breadcrumbsMixin],

  provide() {
    return {
      pageType: this.$options.pageType
    }
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      // reset DMTO item details
      if (
        vm.$options.pageType !== 'review' &&
        vm.$store.getters['customItem/customItem'].dataType === 3
      )
        vm.$store.dispatch('customItem/resetCustomItem')
    })
  },

  beforeRouteLeave(to, from, next) {
    const paramsToPick = ['fullPath', 'meta', 'name', 'params', 'path', 'query']
    this.$store.dispatch('navigation/setLastVisitedPage', {
      path: pick(from, paramsToPick),
      isDetails: this.$options.pageType === 'details'
    })
    if (this.$options.pageType === 'listing') this.setNextRoute(to) // only if routing between listings will show skeleton overlay
    const savedPath = sessionStorage.getItem('prevPath')
    const isGoingToListing = savedPath && to.path === savedPath
    if (!isGoingToListing && from.path !== savedPath) {
      sessionStorage.removeItem('selectedProductId')
      sessionStorage.removeItem('prevPath')
    }
    next()
  },

  async asyncData(context) {
    const { store } = context
    await store.dispatch('getMeta', context)
  },

  data: () => ({
    metaLinks: [
      {
        rel: 'stylesheet',
        type: 'text/css',
        href: 'https://service.thenaturalsapphirecompany.com/cdn/swiper-bundle.min.css'
      }
    ]
  }),

  computed: {
    ...mapGetters({
      locale: 'locale/locale'
    }),

    isSwap() {
      return process.env.siteName === 'ruby'
    },

    isSapphire() {
      return process.env.siteName === 'sapphire'
    }
  },

  mounted() {
    this.$root.$emit('page-loaded')
    this.$nextTick(() => {
      if (window.lazyUpdate) window.lazyUpdate()
    })
    setTimeout(() => {
      // scroll to top
      window?.scrollTo(0, 0)
    }, 100)
  },

  methods: {
    ...mapActions({
      setNextRoute: 'navigation/setNextRoute'
    })
  }
}
</script>
