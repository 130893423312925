import { mapGetters, mapState } from 'vuex'
import uniqBy from 'lodash/uniqBy'
import { normRoute, normRouteName } from '~~/utils/utils'
import {
  phones,
  companyName,
  host,
  omitTitlePostfix,
  title as postfix
} from '~~/utils/definitions/defaults'

const defaultStructuredData = {
  '@context': 'http://schema.org',
  '@type': 'WebSite',
  name: postfix,
  url: host[process.env.siteName]
}

const napStructuredData = {
  '@context': 'http://schema.org',
  '@type': 'LocalBusiness',
  address: {
    '@type': 'PostalAddress',
    streetAddress: '608 5th Avenue, 11th Floor Penthouse',
    addressRegion: 'New York, NY 10020',
    addressCountry: 'United States'
  },
  description: 'Head Office, Showroom & Workshop',
  name: companyName[process.env.siteName],
  priceRange: '$$',
  image: `${host[process.env.siteName]}images/hi-res/logo@2x.jpg`,
  telephone: phones.us[process.env.siteName]
}

export default {
  head() {
    return {
      htmlAttrs: {
        lang: this.$i18n.locale
      },
      title: this.getTitle(),
      meta: this.getMetaTags(),
      __dangerouslyDisableSanitizers: ['script'],
      script: this.getScripts(),
      link: this.getLinks()
    }
  },

  data: () => ({
    defMetaTags: [],
    metaLinks: []
  }),

  computed: {
    ...mapGetters({
      metaTags: 'meta',
      pageTitle: 'pageTitle',
      locales: 'locale/locales',
      productDetails: 'productDetails/productDetails',
      customStone: 'customItem/customStone'
    }),

    ...mapState({
      saleHeadline: (state) => state.app.saleHeadline
    }),

    isPromo() {
      return !!this.saleHeadline
    },

    isCustomItem() {
      return this.$options.pageType === 'review'
    },

    itemStatus() {
      if (this.isCustomItem) return this.customStone.itemStatus.status || 0
      return this.productDetails.itemStatus.status || 0
    },

    isSold() {
      if (!this.isItemDetailsPage()) return null
      return [2, 3].includes(this.itemStatus)
    },

    meta() {
      return this.metaTags
    },

    totalPagesCount() {
      if (!this.type) return 0
      return this.$store.state[this.type].totalPagesCount
    },

    isDev() {
      return process.env.isDev
    }
  },

  methods: {
    isItemDetailsPage() {
      return ['details', 'review'].includes(this.pageType)
    },

    getTitle() {
      if (omitTitlePostfix.includes(normRouteName(this.$route.name)))
        return this.pageTitle
      return [this.pageTitle, postfix].filter((t) => t).join(' | ')
    },

    getLinks() {
      const link = [
        { rel: 'canonical', href: this.getCanonicalPath(this.locale) }
      ]

      if (this.$options.pageType === 'listing') {
        const paginationLinks = this.getPaginationLinks()
        paginationLinks.forEach((l) => {
          link.push(l)
        })
      }

      this.locales.forEach((loc) => {
        link.push({
          rel: 'alternate',
          href: this.getAlternate(loc),
          hreflang: loc
        })
      })
      return [...link, ...this.metaLinks, ...this.getHeroLinks()]
    },

    getHeroLinks() {
      if (normRouteName(this.$route.name) !== 'index') return []
      return this.getHeroImagesLinks()
    },

    getHeroImagesLinks() {
      if (this.isPromo) return [] // TODO preload promo banners
      const siteName = process.env.siteName
      const imageNamesMap = {
        ruby: {
          mobile: ['branch-ring-mob'],
          tablet: ['branch-ring-tab'],
          desktop: ['branch-ring']
        },
        emerald: {
          mobile: ['mobil-ring'],
          tablet: ['left-ring'],
          desktop: ['left-ring', 'right-ring']
        },
        gemstone: {
          mobile: ['woman'],
          tablet: ['woman'],
          desktop: ['woman']
        },
        sapphire: {
          mobile: ['woman'],
          tablet: ['woman'],
          desktop: ['woman']
        }
      }
      let deviceType = 'desktop'
      if (this.$device.isMobile) deviceType = 'mobile'
      if (this.$device.isTablet) deviceType = 'tablet'

      const urlTemplates = [
        '/images/hi-res-webp/hero/{{}}@2x.webp',
        '/images/hi-res/hero/{{}}@2x.jpg',
        '/images/normal-webp/hero/{{}}.webp',
        '/images/normal/hero/{{}}.jpg'
      ]

      const links = []
      imageNamesMap[siteName][deviceType].forEach((imageName) => {
        urlTemplates.forEach((template) => {
          links.push({
            rel: 'preload',
            as: 'image',
            href: template.replace('{{}}', imageName)
          })
        })
      })
      return links
    },

    getScripts() {
      return [
        {
          innerHTML: JSON.stringify(defaultStructuredData),
          type: 'application/ld+json'
        },
        {
          innerHTML: JSON.stringify(napStructuredData),
          type: 'application/ld+json'
        },
        {
          innerHTML: JSON.stringify(this.structuredData || {}),
          type: 'application/ld+json'
        }
      ]
    },

    getMetaTags() {
      const metaTags = this.$createSeo(this.$route.name, this.meta)
      if (this.isSold)
        metaTags.unshift({
          name: 'robots',
          content: 'noindex'
        })
      return uniqBy([...this.defMetaTags, ...metaTags], 'content')
    },

    getAlternate(locale) {
      const route = normRoute(this.$route)
      return `https://${process.env.prodDomain}${this.localePath(
        route,
        locale
      )}`
    },

    getCanonicalPath() {
      return this.getPath(this.localePath(normRoute(this.$route)))
    },

    getPath(path) {
      return `https://${process.env.prodDomain}${path}`
    },

    getPaginationLinks() {
      const links = []
      const pageNumber = parseInt(this.$route.query.page) || 1
      const pageNumberNext = pageNumber + 1
      const pageNumberPrev = pageNumber - 1
      const route = normRoute(this.$route)

      if (pageNumberNext <= this.totalPagesCount) {
        route.query.page = pageNumberNext
        links.push({ rel: 'next', href: this.getPath(this.localePath(route)) })
      }

      if (pageNumberPrev > 0) {
        route.query.page = pageNumberPrev
        links.push({
          rel: 'prev',
          href: this.getPath(this.localePath(route)).replace(/[&|?]page=1/g, '')
        })
      }

      return links
    }
  }
}
