<template lang="pug">
  main.page.page-landing
    promo-component(v-if="promo")
    hero-component(v-else, landing-category="home", :show-breadcrumbs="false")
    shop-gemstones-component(:view-button="true")
    gemstone-jewelry-component
    packaging-component
    design-your-own-component
    our-story-component
    shop-with-peace
    mining-video-component
    showroom-component
    charity-component

    setting-selector-all(v-if="renderSettingsSelectorAll", @mounted="$root.$emit('settings-selector-all')")
    setting-selector-sale(v-if="promo")
    setting-selector(v-if="renderSettingsSelector", @mounted="$root.$emit('settings-selector')")
    stone-selector(v-if="renderSettingStoneSelector", @mounted="$root.$emit('settings-selector-stone')")
    quick-view(v-if="renderQuickView")
    security-modal(v-if="renderSecurityModal")
    showroom-gallery-modal(v-if="renderGalleryModal", @change="onImageChanged", :firstImage="firstImage", :images="images", :currentImage="currentImage")
    back-to-top-component
</template>

<script>
import { mapState } from 'vuex'
import { hydrateWhenIdle, hydrateWhenVisible } from 'vue-lazy-hydration'
import _basePage from '~~/components/Page/_basePage'

export default {
  name: 'HomePage',

  pageType: 'home',

  components: {
    SettingSelectorSale: hydrateWhenIdle(() =>
      import('~~/components/DetailsPage/DetailsMain/SettingsSelectorSale')
    ),
    ShopWithPeace: hydrateWhenIdle(() =>
      import('~/components/HomePage/ShopWithPeace')
    ),
    SettingSelectorAll: hydrateWhenIdle(() =>
      import('~~/components/DetailsPage/DetailsMain/SettingsSelectorAll')
    ),
    SettingSelector: hydrateWhenIdle(() =>
      import('~~/components/DetailsPage/DetailsMain/SettingsSelector')
    ),
    StoneSelector: hydrateWhenIdle(() =>
      import('~~/components/modals/StoneSelector')
    ),
    HeroComponent: hydrateWhenIdle(
      /* webpackPreload: true */ () => import('~/components/HomePage/Hero')
    ),
    ShopGemstonesComponent: hydrateWhenIdle(() =>
      import('~/components/HomePage/ShopGemstones')
    ),
    DesignYourOwnComponent: hydrateWhenIdle(() =>
      import('~/components/HomePage/DesignYourOwn')
    ),
    PromoComponent: hydrateWhenIdle(
      /* webpackPreload: true */ () => import('~~/components/HomePage/Promo')
    ),
    OurStoryComponent: hydrateWhenVisible(
      () => import('~/components/HomePage/OurStory'),
      { observerOptions: { rootMargin: '200px' } }
    ),
    PackagingComponent: hydrateWhenVisible(
      () => import('~/components/HomePage/Packaging/Packaging'),
      { observerOptions: { rootMargin: '200px' } }
    ),
    QuickView: hydrateWhenIdle(() =>
      import('~~/components/ListingPage/QuickView')
    ),
    ShowroomComponent: hydrateWhenVisible(
      () => import('~/components/HomePage/Showroom'),
      { observerOptions: { rootMargin: '200px' } }
    ),
    MiningVideoComponent: hydrateWhenVisible(
      () => import('~/components/HomePage/MiningVideo'),
      { observerOptions: { rootMargin: '200px' } }
    ),
    SecurityModal: hydrateWhenIdle(() =>
      import('~~/components/Showroom/SecurityModal')
    ),
    ShowroomGalleryModal: hydrateWhenIdle(() =>
      import('~~/components/Showroom/GalleryModal')
    ),
    CharityComponent: hydrateWhenIdle(() =>
      import('~/components/HomePage/Charity')
    ),
    GemstoneJewelryComponent: hydrateWhenIdle(() =>
      import('~/components/HomePage/GemstoneJewelry')
    )
  },

  extends: _basePage,

  data: () => ({
    renderQuickView: false,
    renderSettingsSelectorAll: false,
    renderSettingsSelector: false,
    renderSecurityModal: false,
    renderGalleryModal: false,
    renderSettingStoneSelector: false,
    firstImage: 0,
    currentImage: {}
  }),

  computed: {
    ...mapState({
      saleHeadline: (state) => state.app.saleHeadline
    }),

    promo() {
      return this.saleHeadline
    },

    images() {
      return [
        {
          id: '515535de-23a3-4c89-ba1d-5a11d7c1d559',
          text: this.$t('showroom.images.image1'),
          thumbnail: 'showroom/thumbnails/showroom_full-06@2x.jpg',
          src: 'showroom/showroom_full-06@2x.jpg'
        },
        {
          id: '780b2887-7a8d-4cbc-bd28-84c8ad385280',
          text: this.$t('showroom.images.image2'),
          thumbnail: 'showroom/thumbnails/showroom_full-01@2x.jpg',
          src: 'showroom/showroom_full-01@2x.jpg'
        },
        {
          id: '412fb693-a30c-41c8-9f46-d33d1a111fb3',
          text: this.$t('showroom.images.image3'),
          thumbnail: 'showroom/thumbnails/showroom_full-15@2x.jpg',
          src: 'showroom/showroom_full-15@2x.jpg'
        },
        {
          id: '8d5bfed6-a4cc-4c3b-abb3-e5d3f3ae9185',
          text: this.$t('showroom.images.image4'),
          thumbnail: 'showroom/thumbnails/showroom_full-11@2x.jpg',
          src: 'showroom/showroom_full-11@2x.jpg'
        },
        {
          id: '61a3b8a9-9c7f-4235-a5ec-763925e0c968',
          text: this.$t('showroom.images.image5'),
          thumbnail: 'showroom/thumbnails/showroom_full-10@2x.jpg',
          src: 'showroom/showroom_full-10@2x.jpg'
        },
        {
          id: 'd0c38aa9-ad61-4e21-9619-fe100afe3923',
          text: this.$t('showroom.images.image6'),
          thumbnail: 'showroom/thumbnails/showroom_full-08@2x.jpg',
          src: 'showroom/showroom_full-08@2x.jpg'
        },
        {
          id: '2c26f9ab-15dd-4b6a-8b32-8c2ae5345367',
          text: this.$t('showroom.images.image7'),
          thumbnail: 'showroom/thumbnails/showroom_full-07@2x.jpg',
          src: 'showroom/showroom_full-07@2x.jpg'
        }
      ]
    }
  },

  created() {
    this.onImageChanged(0)
  },

  mounted() {
    this.$root.$once('gemologist-visible', this.onGemologistVisible)
    this.$root.$once('settings-selector-all', this.showSettingsSelectorAll)
    this.$root.$once('settings-selector', this.showSettingsSelector)
    this.$root.$once('settings-selector-stone', this.showSettingsSelectorStone)
    this.$root.$once('security-requirements', this.showSecurityModal)
    this.$root.$once('showroom-gallery', this.showGalleryModal)
  },

  methods: {
    /**
     * Render quick view only when gemologist section is visible.
     * For performance reasons
     */
    onGemologistVisible() {
      this.renderQuickView = true
    },

    /**
     * Avoid an excessive DOM size
     */
    showSettingsSelectorAll() {
      this.renderSettingsSelectorAll = true
    },

    /**
     * Avoid an excessive DOM size
     */
    showSettingsSelector() {
      this.renderSettingsSelector = true
    },

    /**
     * Avoid an excessive DOM size
     */
    showGalleryModal() {
      this.renderGalleryModal = true
    },

    /**
     * Avoid an excessive DOM size
     */
    showSecurityModal() {
      this.renderSecurityModal = true
    },

    /**
     * Avoid an excessive DOM size
     */
    showSettingsSelectorStone() {
      this.renderSettingStoneSelector = true
    },

    onImageChanged(index) {
      this.firstImage = index
      this.currentImage = this.images[index]
    }
  }
}
</script>

<style lang="sass" scoped>
.page-landing
  @include margin-bottom()
</style>
