export default {
  data: () => ({
    breadcrumbs: []
  }),

  methods: {
    async breadcrumbsItems() {
      const breadcrumbs = await import(
        `../utils/definitions/breadcrumbs/${process.env.siteName}Breadcrumbs.js`
      )
      return breadcrumbs.default.call(this)
    }
  }
}
